<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{title}} Practical exam results
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{ title }} Practical exam results
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="2">
                  <v-text-field
                      v-model="search.candidate"
                      v-on:keyup.enter="getPracticalExamResults"
                      @input="search.candidate = $event !== null ? $event : ''"
                      label="Search by enrolment key or candidate name"
                      outlined
                      dense
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" md="2">
                  <v-select
                      label="Mark"
                      v-model="search.marked_obtained"
                      :items="symbols"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2" v-if="search.marked_obtained">
                  <v-text-field
                      label="Mark obtained"
                      v-model="search.marks"
                      v-on:keyup.enter="getPracticalExamResults()"
                      @input="search.marks = $event !== null ? $event : ''"
                      outlined
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="2">
                  <v-autocomplete 
                    dense 
                    outlined 
                    label="Examiner"
                    :search-input.sync="examinerSearch" 
                    v-model="search.examiner_id"
                    :items="examiners" 
                    clearable 
                    v-on:keyup.enter="getPracticalExamResults"
                    item-text="display_text" 
                    item-value="id" 
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="2">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        prepend-inner-icon="mdi-calendar"  
                        @input="search.marked_date = $event !== null ? $event : ''"
                        :value="computedDateFormattedMomentjs"
                        readonly 
                        outlined 
                        prepend-icon=""
                        dense 
                        clearable
                        v-bind="attrs" v-on="on"
                      >
                        <template v-slot:label>
                          Marked date <span class="text-danger">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      outlined v-model="search.marked_date"
                      dense
                      no-title
                      @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="2" class="pull-right">
                  <v-btn @click.prevent="getPracticalExamResults()" class="btn btn-primary" :loading="loading"  style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>
              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="text-left">
                    <th class="px-3">Enrolment key</th>
                    <th class="px-3">Mark obtained</th>
                    <th class="px-3">Added by</th>
                    <th class="px-3">Examiner</th>
                    <th class="px-3">Mark type</th>
                    <th class="px-3">Live document</th>
                    <th class="px-3">Options</th>
                  </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in practical_exam_results" :key="index">
                    <td class="text-left">
                      <div class="">
                        {{ item.candidate_full_name ?  item.candidate_full_name : '--'}}
                      </div>
                      <a href="#" @click="getCandidateSummary(item.candidate_exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ item.candidate_exam_key ?  item.candidate_exam_key : ''}}
                      </a>
                    </td>
                    <td class="text-left">
                      {{ item.mark_obtained }}
                    </td>
                    <td class="text-left">
                      {{ item.addedBy ? item.addedBy : '--' }}
                    </td>
                    <td class="text-left">
                      {{ item.examiner_name ? item.examiner_name : '--'}}
                    </td>
                    <td class="text-left">
                      {{ item.mark_type_text ? item.mark_type_text : '--' }}
                    </td>
                    <td class="text-left">
                      <div v-html="item.additional_info"></div>
                      <span class="badge" v-bind:class="{ 'badge-success': item.is_main, 'badge-danger': !item.is_main }">{{ item.is_main ? 'Yes' : 'No' }}</span>
                    </td>
                    <td>
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="getCandidateSummary(item.candidate_exam_key)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-eye</v-icon>
                                                </span>
                                <span class="navi-text">Candidate details</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="viewAccountHolderInfo(item.owner_id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-user</v-icon>
                                                </span>
                                <span class="navi-text">Enroller profile</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="item.candidate_exam_status=='assessed'">
                              <a class="navi-link" @click="manageReport(item.candidate_exam_key)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-file-pdf</v-icon>
                                                </span>
                                <span class="navi-text">Manage report and result</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                <tr v-if="practical_exam_results.length == 0">
                  <td colspan="13">No results available</td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="practical_exam_results.length > 0"
                  class="pull-right mt-7"
                  @input="getPracticalExamResults"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  :disabled="loading"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>

import CandidatePracticalExamResultService from "@/services/candidate/practical-exam-result/CandidatePracticalExamResultService";
import ExaminerService from "@/services/user/examiner/ExaminerService";
import moment from 'moment'
import { format, parseISO } from 'date-fns'

const practicalExamResult = new CandidatePracticalExamResultService();
const examiner = new ExaminerService();

export default {

  data(){
    return{
      practical_exam_results: [],
      total: null,
      title: '',
      perPage: null,
      page: null,
      menuDate: false,
      loading: false,
      search: {
        candidate: '',
        marked_obtained: '',
        marks: '',
        examiner_id: null,
        marked_date: ''
      },
      selectExaminer: {},
      selectExaminers: [],
      examiners:[],
      examinerSearch: null,
      menu: false,
      symbols: [
        { name: "Equal", value: "=" },
        { name: "Greater than", value: ">" },
        { name: "Smaller than", value: "<" },
        { name: "Greater than or equal to", value: ">=" },
        { name: "Smaller than or equal To", value: "<=" },
      ]
    }
  },
  methods:{
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
    getPracticalExamResults(){
      this.loading = true;
      practicalExamResult
          .paginate(this.search, this.page)
          .then(response => {
            this.practical_exam_results = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    viewAccountHolderInfo(accountHolderId) {
      this.$router.push({
        name: 'accountholder',
        params: {search: accountHolderId}
      })
    },
    manageReport(examKey){
      this.$router.push({
        name: 'practical-exam-result-report',
        params: {examKey: examKey}
      })
    },
  },
  watch:{
    examinerSearch(val) {
      examiner
        .all(val)
        .then((response) => {
          response.data.examiners.map((examiner) => {
            let data = examiner;
            data.display_text =
              examiner.first_name +
              " " +
              examiner.last_name +
              " | " +
              examiner.unique_identifier;
            this.examiners.push(data);
          });
        })
        .catch((err) => {

        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted(){
    this.getPracticalExamResults();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.search.marked_date ? moment(this.search.marked_date).format('MM-DD-YYYY') : ''
    },

  }
}
</script>